import React from "react";
import Skill from './skill/Skill'
const Skills = () => {
  return (
    <div>
      {/* <!--skills section--> */}
      <section id="skills" className="pb-2">
        <div className="container">          
          {/* <!--  Skills title--> */}
          <div className="row title mb-5">
            <div className="col text-center">
              <h1 className="text-uppercase">my skills</h1>
              <h3 className="text-capitalize">
                I am a Web Developer with the following skills
              </h3>
              <a href="javascrip:void(0)" className="mb-0">
                <i className="far fa-check-square"></i>
              </a>
              <div className="title-underline"></div>
            </div>
          </div>
          {/* <!--      end of title--> */}
          <div className="row">
            {/* skills */}
            <Skill/>          
          </div>
        </div>
      </section>
    </div>
  );
};
export default Skills;
