import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "../components/header/Header";
import AboutMe from "../components/aboutme/AboutMe";
import Skills from "../components/skills/Skills";
// import MyProjects from '../components/myprojects/MyProjects';
import MyValues from "../components/myvalues/MyValues";
// import ContactForm from '../components/contactForm/ContactForm';
import Footer from "../components/footer/Footer";

const MyProjects = React.lazy(() =>
  import("../components/myprojects/Myprojects")
);

const routes = (
  <BrowserRouter>
    <div>
      <Route path="/" component={Header} />
      <Route path="/" component={Skills} />
      <Route path="/" component={AboutMe} />
      {/* <Route path="/" component={MyProjects} /> */}
      <Route
        path="/"
        render={() => (
          <Suspense fallback={<div>Loading...</div>}>
            <MyProjects />
          </Suspense>
        )}
      />

      <Route path="/" component={MyValues} />
      {/* <Route path="/" component={ContactForm} />     */}
      <Route path="/" component={Footer} />
    </div>
  </BrowserRouter>
);
const AppRouter = () => {
  return <div>{routes}</div>;
};
export default AppRouter;
