import React from "react";
const MyValues = () => {
  const [counter, setCounter] = React.useState(0); 
 
  // set counter to increment page views to 1234
  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    const timer =
      counter > 0 &&
      counter < 1234 &&
      setInterval(() => setCounter(counter + 1), 100);
    return () => clearInterval(timer);
  }, [counter]);

  function listenToScroll(e) {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    // const scrolled = winScroll / height;
    // console.log("scrolled", scrolled);
    // console.log("height", height);
    // console.log("winScroll", winScroll);
    //winScroll height is checked to set the counter
    // console.log("height",height);
    // console.log("winScroll",winScroll);   
    if (winScroll+40 >= height) {      
      setCounter(1);
    }
  }
  return (
    <div>
      <section id="values" className="pb-5 pt-0">
        <div className="row title mb-5">
          <div className="col text-center">
            <h1 className="text-uppercase">my values</h1>
            <h3 className="text-capitalize">
              The measure of a man is what he does with power.
            </h3>
            <a href="/#" className="mb-0">
              <i className="fas fa-rocket"></i>
            </a>
            <div className="title-underline"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4 value text-center my-3">
              <span className="value-icon">
                <i className="fas fa-utensils fa-fw"></i>
              </span>
              <span className="value-number">1</span>
              <h1 className="my-2 text-uppercase">eat</h1>
            </div>
            <div className="col-sm-6 col-lg-4 value text-center my-3">
              <span className="value-icon">
                <i className="fas fa-code fa-fw"></i>
              </span>
              <span className="value-number">2</span>
              <h1 className="my-2 text-uppercase">code</h1>
            </div>
            <div className="col-sm-6 col-lg-4 mx-auto value text-center my-3">
              <span className="value-icon">
                <i className="fas fa-bed fa-fw"></i>
              </span>
              <span className="value-number">3</span>
              <h1 className="my-2 text-uppercase">sleep</h1>
            </div>
          </div>
        </div>
      </section>
      <section id="numbers">
        <div className="container-fluid no-padding">
          <div className="row">
            <div className="col-sm-6 col-lg-3 text-center number py-3">
              <span>
                <i className="fas fa-user fa-3x"></i>
              </span>
              <h4 className="mt-3">{counter}</h4>

              <h3 className="text-capitalize">users</h3>
            </div>
            <div className="col-sm-6 col-lg-3 text-center number py-3">
              <span>
                <i className="fas fa-download fa-3x"></i>
              </span>
              <h4 className="mt-3">{counter}</h4>

              <h3 className="text-capitalize">downloads</h3>
            </div>
            <div className="col-sm-6 col-lg-3 text-center number py-3">
              <span>
                <i className="fas fa-share-alt fa-3x"></i>
              </span>
              <h4 className="mt-3">{counter}</h4>

              <h3 className="text-capitalize">shares</h3>
            </div>
            <div className="col-sm-6 col-lg-3 text-center number py-3">
              <span>
                <i className="fas fa-eye fa-3x"></i>
              </span>
              <h4 className="mt-3">{counter}</h4>
              <h3 className="text-capitalize">views</h3>             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default MyValues;
