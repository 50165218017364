import React from "react";

const SkillsProgress = () => {
  const skills = [
    {
      skill_name: "Javascript",
      skill_progress: 75 + "%",
    },
    {
      skill_name: "Angular",
      skill_progress: 75 + "%",
    },
    {
      skill_name: "React JS",
      skill_progress: 65 + "%",
    },
    {
      skill_name: "Python",
      skill_progress: 55 + "%",
    },
    {
      skill_name: "Typescript",
      skill_progress: 45 + "%",
    },
    {
      skill_name: "Node JS",
      skill_progress: 40 + "%",
    },
    {
      skill_name: "HTML",
      skill_progress: 75 + "%",
    },
    {
      skill_name: "Photoshop",
      skill_progress: 60 + "%",
    },
  ];
  // console.log("skills", skills);
  // const listItems = skills.map((d) => (
  //   <li key={d.skill_name}>{d.skill_progress}</li>
  // ));
  const renderSkills = skills.map((skill, index) => (
    <div className="progress-bars" key={index}>
      <div className="bar my-3">
        <h5 className="text-light text-uppercase d-flex justify-content-between">
          {skill.skill_name}
          <span>{skill.skill_progress}</span>
        </h5>
        <div className="progress bg-light">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: skill.skill_progress }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {skill.skill_progress}
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div>
      {/* skills */}
      {renderSkills}
    </div>
  );
};

export default SkillsProgress;
