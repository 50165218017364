import React from "react";
import SkillsProgress from "./SkillsProgress/SkillsProgress";
const AboutMe = () => {
  return (
    <div>
      <section id="about" className="pb-5">
        <article
          id="modal-filler"
          className="d-flex align-items-center justify-content-center"
        ></article>

        <div className="container">
          {/* <!--     title--> */}
          <div className="row title my-5">
            <div className="col text-center">
              <h1 className="text-uppercase">about me</h1>
              <h3 className="text-capitalize">
                I am a highly competent Front End Developer with a proven track
                record in designing websites. I have strong technical skills as
                well as excellent interpersonal skills, enabling me to interact
                with a wide range of clients. I am eager to be challenged in
                order to grow and further improve my IT skills.{" "}
              </h3>
              <a href="javascrip:void(0)" className="mb-0">
                <i className="fas fa-globe"></i>
              </a>
              <div className="title-underline"></div>
            </div>
          </div>
          {/* <!--      end of title--> */}
          <div className="row mb-5">
            <div className="col-md-6 about-progress mb-5 d-flex flex-column justify-content-center">
              {/* Progress SKills */}
              <SkillsProgress />
            </div>
            <div className="col-md-6 py-5 about-text text-center">
              <h2 className="text-uppercase">
                always learning and pushing boundries
              </h2>

              <p className="lead text-light"></p>
              {/* <a
                href="https://www.nithinsamuel.com/resume/Nithin_Samuel_Resume.pdf"
                className="btn btn-lg d-block w-75 mx-auto text-uppercase mb-5"
                target="_blank"
                rel="noopener noreferrer"
              >
                view resume
              </a> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--end of about--> */}
    </div>
  );
};

export default AboutMe;
