import React from "react";

const Header = () => {
  return (
    <div>
      {/* Header Links */}
      <div className="container-fluid icon-top py-2 px-3">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-baseline">
            <div className="top-icons">
              <a
                href="https://www.facebook.com/nithin.samuel.me/"
                target="_blank"
                className="mx-2"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://twitter.com/nithinspartan77"
                target="_blank"
                className="mx-2"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://github.com/the-javascript-jedi"
                target="_blank"
                className="mx-2"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
              {/* <a
                href="https://www.linkedin.com/in/nithin-samuel-48bb4b5b/"
                target="_blank"
                className="mx-2"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a> */}
              <a
                href="https://www.instagram.com/nithin.samuel/"
                target="_blank"
                className="mx-2"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <h5 className="text-capitalize">&copy;Nithin Samuel </h5>
          </div>
        </div>
      </div>
      {/* Header */}
      <header id="header">
        <nav id="navbar" className="navbar navbar-expand-lg">
          <a className="navbar-brand px-2 py-0 " href="javascrip:void(0)">
            Nithin Samuel
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </button>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link m-2 text-capitalize nav-active"
                  href="#header"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link m-2 text-capitalize" href="#skills">
                  Skills
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link m-2 text-capitalize" href="#about">
                  about
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link m-2 text-capitalize" href="#projects">
                  projects
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container-fluid">
          <div className="row">
            <div className="col no-padding">
              <div
                id="demo-slider"
                className="carousel slide"
                data-ride="carousel"
              >
                {/* Indicators */}
                {/* <ol className="carousel-indicators">
                  <li
                    data-target="#demo-slider"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#demo-slider" data-slide-to="1"></li>
                  <li data-target="#demo-slider" data-slide-to="2"></li>
                </ol> */}
                {/* <!-- The slideshow --> */}
                <div className="carousel-inner">
                  <div className="carousel-item item-1 active">
                    <div className="carousel-caption text-right">
                      <h1 className="display-3 text-capitalize mb-2 text-light">
                        You can't stop the waves but you can learn to surf
                      </h1>
                      {/* <!-- <a href="#projects" className="btn btn-lg mb-5 text-uppercase banner-btn">my work</a> --> */}
                    </div>
                  </div>
                  <div className="carousel-item item-2">
                    <div className="carousel-caption text-center">
                      <h1 className="display-3 text-capitalize mb-2 text-light">
                        Don't limit your challenges, Challenge your limits.
                      </h1>
                      {/* <!-- <a href="#projects" className="btn btn-lg mb-5 text-uppercase banner-btn">my work</a> --> */}
                    </div>
                  </div>
                  <div className="carousel-item item-3">
                    <div className="carousel-caption text-left">
                      <h1 className="display-3 text-capitalize mb-2 text-light">
                        If your dreams don't scare you They are too small.
                      </h1>
                      {/* <!-- <a href="#projects" className="btn btn-lg mb-5 text-uppercase banner-btn">my work</a> --> */}
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#demo-slider"
                  role="button"
                  data-slide="prev"
                >
                  <span className="carousel-control-prev-icon"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#demo-slider"
                  role="button"
                  data-slide="next"
                >
                  <span className="carousel-control-next-icon"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
