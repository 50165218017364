import React from "react";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    // <!--footer-->
    <footer className="container-fluid py-2 px-3">
      <div className="row">
        <div className="col d-flex justify-content-between align-items-baseline">
          <div className="footer-icons">
            <a
              href="https://www.facebook.com/nithin.samuel.me/"
              target="_blank"
              className="mx-2"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://twitter.com/nithinspartan77"
              target="_blank"
              className="mx-2"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://github.com/the-javascript-jedi"
              target="_blank"
              className="mx-2"
              rel="noopener noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
            {/* <a
              href="https://www.linkedin.com/in/nithin-samuel-48bb4b5b/"
              target="_blank"
              className="mx-2"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a> */}
            <a
              href="https://www.instagram.com/nithin.samuel/"
              target="_blank"
              className="mx-2"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
          <h5 className="text-capitalize">{currentYear} &copy; Nithin</h5>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
